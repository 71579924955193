<template>
  <b-row>
    <b-col cols="8">
      <label for="kit" class="m-0">Layout</label>
      <div class="input-search">
        <SearchIcon class="icon pt-1"/>
        <b-input
          id="kit"
          v-model="filters.name"
          class="form-control pl-5"
          placeholder="Buscar layout"
          debounce="500"
        />
      </div>
    </b-col>
    <!-- Será implementado futuramente
    <b-col cols="4">
      <label for="kit" class="m-0 pb-1">Tipo</label>
      <Select
        :value="filters.type"
        :options="layoutTypes"
        @onChange="value => (filters.type = value)"
      />
    </b-col> -->
  </b-row>
</template>

<script>
export default {
  name: 'LayoutFilters',
  components: {
    // Select: () => import('@/components/CheckboxSelect.vue'),
    SearchIcon: () => import('@/assets/icons/search.svg')
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      layoutTypes: ['Todos', 'Despesas', 'Procedimentos'],
    }
  }
}
</script>

<style lang="scss" scoped>

.input-search {

  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    left: 20px;
    top: 45%;
    stroke: var(--neutral-500);
  }

}
label {
  font-size: 1rem;
  color: var(--dark-blue);
  font-weight: 600 !important;
}
</style>